// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    )
    document.querySelector('head').appendChild(msViewportStyle)
}

// Jquery functions
jQuery(document).ready(function () {
    // LazyLoading
    jQuery("img").unveil(500, function () {
        jQuery(this).on('load', function () {
            jQuery(this).removeAttr("data-src");
        });
    });
    jQuery("#nav-opener").click(function () {
        jQuery(this).toggleClass("is-active");
        jQuery("#nav-container").toggleClass("is-active");
    });
    //jQuery("#nav-container").doubleTapToGo();

    //top slider
    jQuery("#intro-slider").on('init', function (event, slick) {
        $(this).find('[data-slick-index="0"]').find('.intro__slide').addClass('in');
    }).on('afterChange', function (event, slick, currentSlide) {
        $(this).find('.slick-slide:not(".slick-active") .intro__slide').removeClass('in');
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $(this).find('[data-slick-index="' + nextSlide + '"]').find('.intro__slide').addClass('in');
    }).slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 750,
        fade: true,
        cssEase: 'ease',
        swipe: false,
        pauseOnHover: false,
        pauseOnFocus: false
    });

    jQuery('.continue-arrow, a.up').click(function () {
        var targetElement = jQuery(jQuery(this).attr('href'));
        jQuery('html, body').animate({
            scrollTop: targetElement.offset().top - 60
        }, 1000);

        return this;
    });

    var $elements = $('#top, #place, #contact, #voucher');
    $elements.waypoint({
        handler: function (direction) {
            if (direction === 'down') {
                changeMainNavCurrentItemClass(this.element.id);
                if (this.element.id == 'voucher') {
                    hpAnimation.runIn();
                }
            }
        },
        offset: '50%'
    });
    $elements.waypoint({
        handler: function (direction) {
            if (direction === 'up') {
                changeMainNavCurrentItemClass(this.element.id);
            }
        },
        offset: function () {
            return -this.element.clientHeight
        }
    });
    //	$('#voucher').waypoint({
    //		handler: function(direction)
    //		offset: '50%'
    //	});

    function changeMainNavCurrentItemClass(pageName) {
        jQuery("#waypoints a").removeClass("is-active");
        jQuery("#waypoints a[href='#" + pageName + "']").addClass("is-active");
    }

    jQuery('#waypoints a').click(function (event) {
        event.preventDefault();
        var targetElement = jQuery(jQuery(this).attr('href'));
        jQuery('html, body').animate({
            scrollTop: targetElement.offset().top - 60
        }, 1000);
    });

    hpAnimation.init();

    jQuery("#gallery").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 330,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    var lightbox = $('#gallery a').simpleLightbox();

    /**
     * articles loading
     */
    var $loadMoreArticles = $("#loadMore");
    var $articlesContainer = $("#articlesContainer");

    if ($loadMoreArticles.length > 0 && $articlesContainer.length > 0) {
        $loadMoreArticles.on("click", function (ev) {
            ev.preventDefault();

            $loadMoreArticles.addClass("is-loading");

            $.post(
                $loadMoreArticles.attr("href"), {
                    offset: $articlesContainer.children("article").length
                },
                function (data) {
                    $loadMoreArticles.removeClass("is-loading");

                    if (data.items.length) {
                        $articlesContainer.append(data.items);
                    }

                    if (!data.loadMore) {
                        $loadMoreArticles.addClass("invisible");
                    }
                }
            );
        });
    }

    ScrollApp.init();
});

var hpAnimationObject = function () {
    var self = this;

    self.init = function () {

        self.duration = 3;
        self.container = $('.flowers');
        self.flower1 = $('.span-1-flower', self.container);
        self.flower2 = $('.span-2-flower', self.container);
        self.flower3 = $('.span-3-flower', self.container);
        self.flower4 = $('.span-4-flower', self.container);
        self.text1 = $('.span-1', self.container);
        self.text2 = $('.span-2', self.container);
        self.text3 = $('.span-3', self.container);
        self.text4 = $('.span-4', self.container);
        self.flowers = [self.flower1, self.flower2, self.flower3, self.flower4];
        self.texts = [self.text1, self.text2, self.text3, self.text4];


        self.tl = new TimelineLite({
            paused: true
        });
        //self.tl.fromTo(self.container, self.duration, {autoAlpha: 0, scale: 0.5}, {autoAlpha: 1, scale: 1}, 0);

        self.tl.fromTo(self.flower1, self.duration, {
            autoAlpha: 1,
            scale: 0.5,
            y: '30px',
            x: '200px'
        }, {
            autoAlpha: 1,
            scale: 1,
            y: 0,
            x: 0
        }, 0);
        self.tl.fromTo(self.flower2, self.duration, {
            autoAlpha: 1,
            scale: 0.5,
            y: '-90px',
            x: '230px'
        }, {
            autoAlpha: 1,
            scale: 1,
            y: 0,
            x: 0
        }, 0.5);
        self.tl.fromTo(self.flower3, self.duration, {
            autoAlpha: 1,
            scale: 0.5,
            y: '90px',
            x: '-230px'
        }, {
            autoAlpha: 1,
            scale: 1,
            y: 0,
            x: 0
        }, 0);
        self.tl.fromTo(self.flower4, self.duration, {
            autoAlpha: 1,
            scale: 0.5,
            y: '-90px',
            x: '-200px'
        }, {
            autoAlpha: 1,
            scale: 1,
            y: 0,
            x: 0
        }, 0.5);
        self.tl.staggerFromTo(self.texts, 1, {
            delay: self.duration * 2,
            autoAlpha: 0
        }, {
            autoAlpha: 1
        }, 0.5);

    }

    self.runIn = function () {
        self.tl.progress(0).play();
    };
    self.runOut = function () {
        self.tl.progress(1).reverse();
    };
};
var hpAnimation = new hpAnimationObject();

jQuery(document).scroll(function () {
    if (jQuery(document).scrollTop() >= 200) {
        jQuery('.header').addClass("scrolled");
    } else {
        jQuery('.header').removeClass("scrolled");
    }
    if (jQuery(document).scrollTop() >= 250) {
        setTimeout(
            function () {
                jQuery('.header').css("top", "0").css("opacity", "1");
            }, 500);
    } else {
        jQuery('.header').removeAttr("style");
    }
});


var ScrollApp = (function () {

    var autoScrollInit = function () {
        if (window.location.hash) {
            if ($(window.location.hash).length) {
                ScrollApp.smoothScroll($(window.location.hash));
            }
            var $alter = $('*[data-hash="' + window.location.hash.substr(1) + '"]');
            if ($alter.length) {
                ScrollApp.smoothScroll($alter);
            }
        }
    };

    var linkScrollInit = function () {
        $(".js-scrollToHash").on("click", function (e) {
            var $evoker = $(this);
            var hash = $evoker.attr("href").split("#")[1];
            console.log($evoker, hash);
            if (hash) {
                var $alter = $("*[data-hash=\"" + hash + "\"]");
                if ($alter.length) {
                    e.preventDefault();
                    ScrollApp.smoothScroll($alter);
                }
            }
        });
    };


    return {
        //main function to initiate the module
        init: function () {

            autoScrollInit();
            linkScrollInit();
        },

        smoothScroll: function (targetParam) {
            var off = targetParam.length ?
                targetParam.offset().top :
                parseInt(targetParam);
            $("body, html").animate({
                    scrollTop: off
                },
                1000
            );
        }
    };
})();


// FORM Fucntions
function getTotal() {
    var total = 0;
    $('.voucher-total').each(function () {
        total += parseFloat(this.innerHTML)
    });

    var price_novat = total / 1.21;
    
    var vat = total - (total  / 1.21);
    
   
    $('.price-novat').html(price_novat.toFixed(2));;
    $('.vat').html(vat.toFixed(2));
    $('.total-price-vat').html(total);
}
$(document).ready(function () {


    getTotal();
});



$('.product-quantity button').click(function (e) {

    e.preventDefault();
    var price = $(this).parents('article').find('.voucher-price');
    var total = $(this).parents('article').find('.voucher-total');
    var input = $(this).parents('.product-quantity').find('input');
    if ($(this).hasClass('plus')) {
        $(input).val($(input).val() * 1 + 1);

    } else if (parseInt($(input).val()) > 0) {
        $(input).val($(input).val() * 1 - 1);
    }

    $(total).html($(price).text() * $(input).val());

    getTotal();
});





$('.voucher-order .btn-next').click(function (e) {

         e.preventDefault();
         var count_val = 0;    

          function scroll_down(){
            var targetElement = $("#step2");
                jQuery('html, body').animate({
                scrollTop: targetElement.offset().top - 100
        }, 1000);

    }


    $('.product-quantity input').each(function(){
        var value =  parseInt($(this).val());


        if( value > 0 ){
             $('.voucher-order .has-error').hide('slow');
             $('.step2').show('slow');
             $('.voucher-order .btn-next').hide(); 

               
                setTimeout(scroll_down, 500)


        }
        count_val += value;
    })

    if(count_val == 0){

             $('.voucher-order .has-error').show('slow');
             $('.step2').hide('slow');
             $('.voucher-order .btn-next').show(); 
    }

});

$('.total-price-vat').change(function(){

    if($('.step2').is(':visible')){
    $('.product-quantity input').each(function(){

        var vall = $(this).val();
        if($(vall) != '0'){
             $('.voucher-order .has-error').hide('slow');
             $('.step2').show('slow');
             $('.voucher-order .btn-next').hide(); 
        }else if( ($(vall) == 0).length == $(vall).length ){
              $('.voucher-order .has-error').show('slow');
              $('.step2').hide();
        } 
    })
};
});



$('.contact-check').click(function(e){
    e.preventDefault();

    function scroll_down(){
    var targetElement = $("#step3");
                jQuery('html, body').animate({
                scrollTop: targetElement.offset().top - 100
        }, 1000);

    }
    $('.order-form input').each(function(){
        var id_this = $(this).prop('id');
        if( id_this != 'phone'){
        has_error('#'+id_this);

        } 
    });

    if( $('.order-form .has-error:visible').length == 0){

        $('.step3').show('slow');
        $('.contact-check').hide();


        
                setTimeout(scroll_down, 500)
        


    }

   
});

$('.order-form input').change(function(){
    if($('.step3').is(':visible') ){
    $('.order-form input').each(function(){
        var id_this = $(this).prop('id');
        if( id_this != 'phone'){
        has_error('#'+id_this);

        } 
    });

    if( $('.order-form .has-error:visible').length == 0){

        $('.step3').show('slow');
        $('.contact-check').hide();
    }else{
         $('.step3').hide('slow');
        $('.contact-check').show();
    }

};
});


function has_error(test){

    var name_error = $(test).closest('div').find('.has-error');

    



    if( $('.order-form ' + test).val() == "" ){

        name_error.show();
    }

    else{

            if(test != '#email'){
                name_error.hide();
            }
            else{

            var email_val = $('.order-form '+test).val();

            if( !isValidEmailAddress(email_val ) ) {

                name_error.show();
                
            }else{
                    name_error.hide();
            }
          }

        
    }

}

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};

$('.step3 .btn-submit').click(function(){


        if ( $('.step3 input.payment-button').is(':checked') == false){
            $('.step3 .has-error').show('slow');

        } else{
             if( $('.step3 .has-error').is(':visible') == true){
             $('.step3 .has-error').hide('slow');
             }

        }



        });
    
      
